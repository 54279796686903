@import '@/components/mixins.scss';

label {
	margin: 0px !important;
}

.ant-form-item {
	margin-bottom: 10px !important;
}

.btnPasswordGenerator {
	padding-top: 30px !important;
}
